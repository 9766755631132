<template>
  <div>
    <!-- maxAirport -->
    <validation-provider v-if="offerEdition" #default="{ errors }" :name="$t('offer.subscription.specifics.max_airport')" rules="numeric">
      <b-form-group :label="$t('offer.subscription.specifics.max_airport')" label-for="offer-subscription-specifics-max-airport">
        <b-overlay :show="offerSubscriptionLoading">
          <b-form-input
            id="offer-subscription-specifics-max-airport"
            v-model="items.maxAirports"
            type="number"
            number
            :placeholder="$t('offer.subscription.specifics.max_airport')"
          />
        </b-overlay>
        <b-tooltip v-if="errors[0]" show target="offer-subscription-specifics-max-airport" placement="bottom" variant="light">
          <small class="text-danger">{{ errors[0] }}</small>
        </b-tooltip>
      </b-form-group>
    </validation-provider>
    <span v-else>{{ $t('offer.subscription.specifics.max_airport') }} : {{ items.maxAirports }}</span>
  </div>
</template>

<script>
export default {
  name: 'OfferPartSubscriptionOptionsAirport',

  props: {
    items: {
      type: Object,
      required: true,
    },
    offerSubscriptionLoading: {
      type: Boolean,
      required: true,
    },
    offerEdition: {
      type: Boolean,
      required: true,
    },
  },
}
</script>
